"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const ckeditor5_core_1 = require("@ckeditor/ckeditor5-core");
const hyperlink_editing_1 = require("./hyperlink.editing");
const hyperlink_ui_1 = require("./hyperlink.ui");
class Hyperlink extends ckeditor5_core_1.Plugin {
    static get requires() {
        return [hyperlink_editing_1.HyperlinkEditing, hyperlink_ui_1.HyperlinkUI];
    }
    static get pluginName() {
        return 'Hyperlink';
    }
}
exports.default = Hyperlink;
