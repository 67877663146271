"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.clearDropdownList = exports.updateDropdownList = exports.getBalloonPositionData = exports.createInputText = exports.createButtonWithText = exports.createButtonWithIcon = exports.lastCharIsWhitespace = exports.isEditorWithUI = exports.wrapInSvgElement = exports.isSuggestionApproved = void 0;
const ckeditor5_ui_1 = require("@ckeditor/ckeditor5-ui");
function isSuggestionApproved(element) {
    const dataSuggestionAttributeExist = !!element.getAttribute('data-suggestion');
    return !dataSuggestionAttributeExist;
}
exports.isSuggestionApproved = isSuggestionApproved;
function wrapInSvgElement(svgPathD, viewBox) {
    const [a, b, c, d] = viewBox !== null && viewBox !== void 0 ? viewBox : [0, 0, 25, 25];
    return `<svg xmlns="http://www.w3.org/2000/svg" viewBox="${a} ${b} ${c} ${d}"><path d="${svgPathD}"></path></svg>`;
}
exports.wrapInSvgElement = wrapInSvgElement;
function isEditorWithUI(editor) {
    return !!editor.ui;
}
exports.isEditorWithUI = isEditorWithUI;
function lastCharIsWhitespace(value) {
    return value.substring(value.length - 1, value.length) === ' ';
}
exports.lastCharIsWhitespace = lastCharIsWhitespace;
function createButtonWithIcon(label, icon, className) {
    const button = new ckeditor5_ui_1.ButtonView();
    button.set({
        label,
        icon,
        tooltip: true,
        class: className
    });
    return button;
}
exports.createButtonWithIcon = createButtonWithIcon;
function createButtonWithText(label, className) {
    const button = new ckeditor5_ui_1.ButtonView();
    button.set({
        label,
        className,
        tooltip: true,
        withText: true
    });
    return button;
}
exports.createButtonWithText = createButtonWithText;
function createInputText(locale, label) {
    const labeledInput = new ckeditor5_ui_1.LabeledFieldView(locale, ckeditor5_ui_1.createLabeledInputText);
    labeledInput.label = label;
    return labeledInput;
}
exports.createInputText = createInputText;
function getBalloonPositionData(editor) {
    const view = editor.editing.view;
    const target = () => {
        const firstRange = view.document.selection.getFirstRange();
        return view.domConverter.viewRangeToDom(firstRange);
    };
    return { target };
}
exports.getBalloonPositionData = getBalloonPositionData;
function updateDropdownList(dropdownView, collection) {
    clearDropdownList(dropdownView);
    (0, ckeditor5_ui_1.addListToDropdown)(dropdownView, collection);
}
exports.updateDropdownList = updateDropdownList;
function clearDropdownList(dropdownView) {
    var _a, _b;
    (_b = (_a = dropdownView.listView) === null || _a === void 0 ? void 0 : _a.items) === null || _b === void 0 ? void 0 : _b.clear();
}
exports.clearDropdownList = clearDropdownList;
