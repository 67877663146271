"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.footnoteIcon = exports.FOOTNOTE_CLASS = exports.TOOLTIP_POSITIONS = exports.DATA_CKE_TOOLTIP_POSITION_KEY = exports.DATA_CKE_TOOLTIP_TEXT_KEY = exports.ADD_FOOTNOTE_COMMAND_ID = exports.FOOTNOTE_ID_KEY = exports.FOOTNOTE_MODEL_ID = exports.CONFIG_KEY = exports.DEFAULT_DROPDOWN_LABEL = void 0;
exports.DEFAULT_DROPDOWN_LABEL = 'Select a Footnote';
exports.CONFIG_KEY = 'footnotes';
exports.FOOTNOTE_MODEL_ID = 'footnote';
exports.FOOTNOTE_ID_KEY = 'footnote-id';
exports.ADD_FOOTNOTE_COMMAND_ID = 'addFootnote';
exports.DATA_CKE_TOOLTIP_TEXT_KEY = 'data-cke-tooltip-text';
exports.DATA_CKE_TOOLTIP_POSITION_KEY = 'data-cke-tooltip-position';
exports.TOOLTIP_POSITIONS = {
    north: 'n',
    south: 's',
    east: 'e',
    west: 'w'
};
exports.FOOTNOTE_CLASS = 'ck-gwtr-footnote';
exports.footnoteIcon = 'M19 2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h4l3 3 3-3h4c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm-5.12 10.88L12 17l-1.88-4.12L6 11l4.12-1.88L12 5l1.88 4.12L18 11l-4.12 1.88z';
