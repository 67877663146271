"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HyperlinkEditing = void 0;
const ckeditor5_core_1 = require("@ckeditor/ckeditor5-core");
const ckeditor5_typing_1 = require("@ckeditor/ckeditor5-typing");
const ckeditor5_utils_1 = require("@ckeditor/ckeditor5-utils");
const utils_1 = require("./utils");
const add_internal_hyperlink_command_1 = require("./add-internal-hyperlink.command");
const models_1 = require("./models");
const remove_internal_hyperlink_command_1 = require("./remove-internal-hyperlink.command");
const HIGHLIGHT_CLASS = 'ck-link_selected';
class HyperlinkEditing extends ckeditor5_core_1.Plugin {
    static get requires() {
        return [ckeditor5_typing_1.TwoStepCaretMovement, ckeditor5_typing_1.Input];
    }
    constructor(editor) {
        super(editor);
    }
    init() {
        const editor = this.editor;
        const sectionNameIdToName = (0, utils_1.getSectionNameIdToNameDictionary)(editor);
        // Allow link attribute on all inline nodes.
        editor.model.schema.extend('$text', {
            allowAttributes: [
                models_1.HYPERLINK_ATTRIBUTE_KEY
            ]
        });
        editor.conversion.for('dataDowncast')
            .attributeToElement({
            model: models_1.HYPERLINK_ATTRIBUTE_KEY,
            view: (jsonPayload, conversionApi) => {
                var _a, _b;
                const payload = JSON.parse(jsonPayload);
                if (!payload) {
                    return;
                }
                const sectionName = isNaN(Number(payload.sectionNameId))
                    ? payload.sectionNameId
                    : sectionNameIdToName[payload.sectionNameId];
                return (0, utils_1.createLinkElement)(payload.href, payload.countryId, sectionName, (_b = (_a = payload.subsectionId) === null || _a === void 0 ? void 0 : _a.toString()) !== null && _b !== void 0 ? _b : '', payload.internal, conversionApi);
            }
        });
        editor.conversion.for('editingDowncast')
            .attributeToElement({
            model: models_1.HYPERLINK_ATTRIBUTE_KEY,
            view: (jsonPayload, conversionApi) => {
                var _a, _b;
                const payload = JSON.parse(jsonPayload);
                if (!payload) {
                    return;
                }
                const sectionName = isNaN(Number(payload.sectionNameId))
                    ? payload.sectionNameId
                    : sectionNameIdToName[payload.sectionNameId];
                payload.href = (0, utils_1.ensureSafeUrl)(payload.href);
                return (0, utils_1.createLinkElement)(payload.href, payload.countryId, sectionName !== null && sectionName !== void 0 ? sectionName : '', (_b = (_a = payload.subsectionId) === null || _a === void 0 ? void 0 : _a.toString()) !== null && _b !== void 0 ? _b : '', payload.internal, conversionApi);
            }
        });
        editor.conversion.for('upcast')
            .elementToAttribute({
            view: {
                name: 'a'
            },
            converterPriority: 'high',
            model: {
                key: models_1.HYPERLINK_ATTRIBUTE_KEY,
                value: (viewElement) => {
                    var _a, _b, _c, _d;
                    const href = (0, utils_1.getHyperlinkAttribute)(viewElement, 'href');
                    const subsectionId = (_a = (0, utils_1.getHyperlinkAttribute)(viewElement, 'subsection')) !== null && _a !== void 0 ? _a : '';
                    const internal = (_b = (0, utils_1.getHyperlinkAttribute)(viewElement, 'internal')) !== null && _b !== void 0 ? _b : '';
                    const hyperlinkPayload = {
                        href: href !== null && href !== void 0 ? href : 'ERROR Upcasting',
                        subsectionId,
                        countryId: (_c = (0, utils_1.getHyperlinkAttribute)(viewElement, 'country-id')) !== null && _c !== void 0 ? _c : 'ERROR Upcasting',
                        sectionNameId: (_d = (0, utils_1.getHyperlinkAttribute)(viewElement, 'section-name')) !== null && _d !== void 0 ? _d : 'ERROR Upcasting',
                        internal: internal === 'true'
                    };
                    return JSON.stringify(hyperlinkPayload);
                }
            }
        });
        // Create linking commands.
        editor.commands.add(models_1.ADD_HYPERLINK_ID, new add_internal_hyperlink_command_1.AddHyperlinkCommand(editor));
        editor.commands.add(models_1.REMOVE_HYPERLINK_ID, new remove_internal_hyperlink_command_1.RemoveHyperlinkCommand(editor));
        // Enable two-step caret movement for `internalHyperlink` attribute.
        const twoStepCaretMovementPlugin = editor.plugins.get(ckeditor5_typing_1.TwoStepCaretMovement);
        twoStepCaretMovementPlugin.registerAttribute(models_1.HYPERLINK_ATTRIBUTE_KEY);
        // Setup highlight over selected link.
        (0, ckeditor5_typing_1.inlineHighlight)(editor, models_1.HYPERLINK_ATTRIBUTE_KEY, 'a', HIGHLIGHT_CLASS);
        // Handle link following by CTRL+click or ALT+ENTER
        this._enableLinkOpen();
    }
    _enableLinkOpen() {
        const editor = this.editor;
        const view = editor.editing.view;
        const viewDocument = view.document;
        // this should be this.listenTo<ViewDocumentKeyDownEvent>
        this.listenTo(viewDocument, 'click', (evt, data) => {
            var _a;
            const shouldOpen = data.domEvent.ctrlKey;
            if (!shouldOpen) {
                return;
            }
            let clickedElement = data.domTarget;
            if ((clickedElement === null || clickedElement === void 0 ? void 0 : clickedElement.tagName.toLowerCase()) != 'a') {
                clickedElement = (_a = clickedElement === null || clickedElement === void 0 ? void 0 : clickedElement.closest('a')) !== null && _a !== void 0 ? _a : null;
            }
            if (!clickedElement) {
                return;
            }
            const url = clickedElement.getAttribute('href');
            if (!url) {
                return;
            }
            evt.stop();
            data.preventDefault();
            (0, utils_1.openLink)(url);
            // TODO: Remove this any when ckeditor types are updated
            // https://github.com/ckeditor/ckeditor5/blob/0e5ddd74e5684897d2d2c87a0e65150c287433d4/packages/ckeditor5-link/src/linkediting.ts#L301
        }, { context: '$capture' });
        // Open link on Alt+Enter.
        this.listenTo(viewDocument, 'keydown', (evt, data) => {
            const linkCommand = editor.commands.get(models_1.ADD_HYPERLINK_ID);
            const url = linkCommand.value;
            const shouldOpen = !!url && data.keyCode === ckeditor5_utils_1.keyCodes.enter && data.altKey;
            if (!shouldOpen) {
                return;
            }
            evt.stop();
            (0, utils_1.openLink)(url);
        });
    }
}
exports.HyperlinkEditing = HyperlinkEditing;
