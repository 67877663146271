"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isBuketTreatyType = exports.mapSubsectionToTrteatyTypeId = exports.getSectionNameIdToNameDictionary = exports.getCountries = exports.createRuleDropdownDefinition = exports.mapRulesToCollection = exports.createSubsectionInvestorCountryDropdownDefinition = exports.mapSubsectionInvestorCountryToCollection = exports.createteatyTypeDropdownDefinition = exports.mapSectionTreatyTypesToCollection = exports.createSubsectionDropdownDefinition = exports.mapSubsectionsToCollection = exports.createSectionDropdownDefinition = exports.mapSectionsToCollection = exports.createCountryDropdownDefinition = exports.mapCountryToCollection = exports.openLink = exports.ensureSafeUrl = exports.createLinkElement = exports.isLinkableElement = exports.isLinkElement = exports.getHyperlinkAttribute = void 0;
const ckeditor5_utils_1 = require("@ckeditor/ckeditor5-utils");
const models_1 = require("./models");
const ckeditor5_ui_1 = require("@ckeditor/ckeditor5-ui");
const ATTRIBUTE_WHITESPACES = /[\u0000-\u0020\u00A0\u1680\u180E\u2000-\u2029\u205f\u3000]/g;
const SAFE_URL = /^(?:(?:https?|ftps?|mailto):|[^a-z]|[a-z+.-]+(?:[^a-z+.:-]|$))/i;
function getHyperlinkAttribute(viewElement, key) {
    var _a;
    return (_a = viewElement.getAttribute(key)) !== null && _a !== void 0 ? _a : null;
}
exports.getHyperlinkAttribute = getHyperlinkAttribute;
function isLinkElement(node) {
    return node.is('attributeElement') && !!node.getCustomProperty('link');
}
exports.isLinkElement = isLinkElement;
function isLinkableElement(element, schema) {
    if (!element) {
        return false;
    }
    return schema.checkAttribute(element.name, models_1.HYPERLINK_ATTRIBUTE_KEY);
}
exports.isLinkableElement = isLinkableElement;
function createLinkElement(href, countryId, sectionName, subsection, internal, { writer }) {
    let attributes = {};
    if (internal) {
        attributes = {
            href,
            target: '_blank',
            internal: 'true',
            ['country-id']: countryId,
            ['section-name']: sectionName,
            ['subsection']: subsection,
        };
    }
    else {
        attributes = {
            href,
            style: href.includes('formdocuments') ? 'pointer-events: none' : '',
            target: '_blank',
        };
    }
    // Priority 5 - https://github.com/ckeditor/ckeditor5-link/issues/121.
    const linkElement = writer.createAttributeElement('a', attributes, { priority: 5 });
    writer.setCustomProperty('link', true, linkElement);
    return linkElement;
}
exports.createLinkElement = createLinkElement;
function ensureSafeUrl(url) {
    const urlString = String(url);
    return isSafeUrl(urlString) ? urlString : '#';
}
exports.ensureSafeUrl = ensureSafeUrl;
function isSafeUrl(url) {
    const normalizedUrl = url.replace(ATTRIBUTE_WHITESPACES, '');
    return !!normalizedUrl.match(SAFE_URL);
}
function openLink(link) {
    window.open(link, '_blank', 'noopener');
}
exports.openLink = openLink;
function mapCountryToCollection(subsections) {
    return new ckeditor5_utils_1.Collection(subsections.map(createCountryDropdownDefinition));
}
exports.mapCountryToCollection = mapCountryToCollection;
function createCountryDropdownDefinition(country) {
    return {
        type: 'button',
        model: new ckeditor5_ui_1.Model({
            withText: true,
            label: country.countryName,
            country
        })
    };
}
exports.createCountryDropdownDefinition = createCountryDropdownDefinition;
function mapSectionsToCollection(sections) {
    return new ckeditor5_utils_1.Collection(sections.map(createSectionDropdownDefinition));
}
exports.mapSectionsToCollection = mapSectionsToCollection;
function createSectionDropdownDefinition(section) {
    return {
        type: 'button',
        model: new ckeditor5_ui_1.Model({
            withText: true,
            label: section.name,
            section
        })
    };
}
exports.createSectionDropdownDefinition = createSectionDropdownDefinition;
function mapSubsectionsToCollection(subsections) {
    return new ckeditor5_utils_1.Collection(subsections.map(createSubsectionDropdownDefinition));
}
exports.mapSubsectionsToCollection = mapSubsectionsToCollection;
function createSubsectionDropdownDefinition(subsection) {
    return {
        type: 'button',
        model: new ckeditor5_ui_1.Model({
            withText: true,
            label: subsection.subsectionDescription,
            subsection
        })
    };
}
exports.createSubsectionDropdownDefinition = createSubsectionDropdownDefinition;
function mapSectionTreatyTypesToCollection(sectionTreatyTypes) {
    return new ckeditor5_utils_1.Collection(sectionTreatyTypes.map(createteatyTypeDropdownDefinition));
}
exports.mapSectionTreatyTypesToCollection = mapSectionTreatyTypesToCollection;
function createteatyTypeDropdownDefinition(sectionTreatyType) {
    return {
        type: 'button',
        model: new ckeditor5_ui_1.Model({
            withText: true,
            label: sectionTreatyType.name,
            sectionTreatyType
        })
    };
}
exports.createteatyTypeDropdownDefinition = createteatyTypeDropdownDefinition;
function mapSubsectionInvestorCountryToCollection(subsectionInvestorCountries) {
    return new ckeditor5_utils_1.Collection(subsectionInvestorCountries.map(createSubsectionInvestorCountryDropdownDefinition));
}
exports.mapSubsectionInvestorCountryToCollection = mapSubsectionInvestorCountryToCollection;
function createSubsectionInvestorCountryDropdownDefinition(subsectionInvestorCountry) {
    return {
        type: 'button',
        model: new ckeditor5_ui_1.Model({
            withText: true,
            label: subsectionInvestorCountry.name,
            subsectionInvestorCountry
        })
    };
}
exports.createSubsectionInvestorCountryDropdownDefinition = createSubsectionInvestorCountryDropdownDefinition;
function mapRulesToCollection(rules) {
    return new ckeditor5_utils_1.Collection(rules.map(createRuleDropdownDefinition));
}
exports.mapRulesToCollection = mapRulesToCollection;
function createRuleDropdownDefinition(rule) {
    return {
        type: 'button',
        model: new ckeditor5_ui_1.Model({
            withText: true,
            label: rule.ruleName,
            rule
        })
    };
}
exports.createRuleDropdownDefinition = createRuleDropdownDefinition;
function getCountries(editor) {
    return editor.config.get(models_1.CONFIG_KEY).countries;
}
exports.getCountries = getCountries;
function getSectionNameIdToNameDictionary(editor) {
    return editor.config.get(models_1.CONFIG_KEY).sectionNameIdToName;
}
exports.getSectionNameIdToNameDictionary = getSectionNameIdToNameDictionary;
function mapSubsectionToTrteatyTypeId(subsectionId) {
    let treatyTypeId;
    switch (subsectionId) {
        case 'taxmatrices-treaty':
            treatyTypeId = 1;
            break;
        case 'taxmatrices-nontreaty':
            treatyTypeId = 2;
            break;
        case 'tax-matrices-TIEA':
            treatyTypeId = 3;
            break;
        case 'taxmatrices-taxhaven':
            treatyTypeId = 4;
            break;
        case 'tm-east-african':
            treatyTypeId = 5;
            break;
        case 'tm-gulf-cooperation':
            treatyTypeId = 6;
            break;
        case 'tm-mutual-agreement':
            treatyTypeId = 7;
            break;
        case 'tm-white-list':
            treatyTypeId = 8;
            break;
        case 'tm-non-treaty-fitms':
            treatyTypeId = 9;
            break;
        case 'tm-tiea-fitms':
            treatyTypeId = 10;
            break;
        case 'taxmatrices-taxhaven':
            treatyTypeId = 11;
            break;
        case 'tm-east-african-fitms':
            treatyTypeId = 12;
            break;
        case 'tm-gulf-cooperation-fitms':
            treatyTypeId = 13;
            break;
        case 'tm-mutual-agreement-fitms':
            treatyTypeId = 14;
            break;
        case 'tm-white-list-fitms':
            treatyTypeId = 15;
            break;
        case 'tm-treaty-white-list-fitms':
            treatyTypeId = 16;
            break;
        default:
            treatyTypeId = 0;
            break;
    }
    return treatyTypeId;
}
exports.mapSubsectionToTrteatyTypeId = mapSubsectionToTrteatyTypeId;
function isBuketTreatyType(treatyTypeId) {
    return treatyTypeId >= 2 && treatyTypeId <= 8;
}
exports.isBuketTreatyType = isBuketTreatyType;
