"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const ckeditor5_core_1 = require("@ckeditor/ckeditor5-core");
const ckeditor5_ui_1 = require("@ckeditor/ckeditor5-ui");
const ckeditor5_engine_1 = require("@ckeditor/ckeditor5-engine");
const utils_1 = require("./utils");
const utils_2 = require("../shared/utils");
const models_1 = require("./models");
const footnotes_form_view_1 = require("./footnotes-form-view");
class FootnoteUI extends ckeditor5_core_1.Plugin {
    constructor() {
        super(...arguments);
        this.balloon = null;
        this.formView = null;
        this.selectedFootnote = null;
        this.footnoteToEdit = null;
        this.footnoteModelElementToBeRemoved = null;
    }
    static get requires() {
        return [ckeditor5_ui_1.ContextualBalloon];
    }
    init() {
        if (!(0, utils_2.isEditorWithUI)(this.editor)) {
            return;
        }
        this.balloon = this.editor.plugins.get(ckeditor5_ui_1.ContextualBalloon);
        this.formView = this.createFormView();
        this.editor.editing.view.addObserver(ckeditor5_engine_1.ClickObserver);
        this.editor.listenTo(this.editor.editing.view.document, 'click', (_, data) => {
            const modelElement = this.editor.editing.mapper.toModelElement(data.target);
            if ((modelElement === null || modelElement === void 0 ? void 0 : modelElement.name) !== models_1.FOOTNOTE_MODEL_ID) {
                return;
            }
            if (!(0, utils_2.isSuggestionApproved)(data.target)) {
                return;
            }
            const footnoteId = Number(modelElement.getAttribute(models_1.FOOTNOTE_ID_KEY));
            const footnote = (0, utils_1.getFootnotes)(this.editor).find(x => x.footnoteId === footnoteId);
            if (!footnote) {
                return;
            }
            if (data.domEvent.shiftKey) {
                if (this.editor.isReadOnly) {
                    return;
                }
                this.footnoteModelElementToBeRemoved = modelElement;
                this.openFootnotePopup();
            }
            else {
                if (footnote.link) {
                    return;
                }
                this.onLeftClick((0, utils_1.formatFootnote)(footnote), footnote.content);
            }
        });
        const addFootnoteCommand = this.editor.commands.get(models_1.ADD_FOOTNOTE_COMMAND_ID);
        if (!addFootnoteCommand) {
            return;
        }
        this.editor.ui.componentFactory.add(models_1.FOOTNOTE_MODEL_ID, () => {
            const button = new ckeditor5_ui_1.ButtonView();
            button.set({
                label: 'Insert Footnote',
                icon: (0, utils_2.wrapInSvgElement)(models_1.footnoteIcon, [0, 0, 23, 23]),
                tooltip: true
            });
            button.bind('isEnabled').to(addFootnoteCommand);
            // When 'Insert footnote' is clicked
            button.on('execute', () => {
                this.openFootnotePopup();
            });
            return button;
        });
    }
    createFormView() {
        var _a;
        const editor = this.editor;
        const formView = new footnotes_form_view_1.FootnotesFormView(this.editor.locale, () => (0, utils_1.getFootnotes)(this.editor));
        this.selectedFootnote = null;
        // When footnotes option is selected
        this.listenTo(formView.dropdownView, 'execute', (evt) => {
            const footnote = evt.source.footnote;
            if (!footnote) {
                return;
            }
            this.selectedFootnote = footnote;
            formView.dropdownView.buttonView.label = (0, utils_1.formatFootnote)(footnote);
            editor.editing.view.focus();
        });
        // When footnotes submit is clicked
        this.listenTo(formView, 'submit', () => {
            var _a;
            if (!this.selectedFootnote ||
                this.selectedFootnote.footnoteId === ((_a = this.footnoteToEdit) === null || _a === void 0 ? void 0 : _a.footnoteId)) {
                this.closeFootnotePopup();
                return;
            }
            this.removeSelectedFootnoteElement();
            editor.execute(models_1.ADD_FOOTNOTE_COMMAND_ID, this.selectedFootnote);
            this.closeFootnotePopup();
        });
        // When footnotes cancel is clicked
        this.listenTo(formView, 'cancel', () => {
            this.closeFootnotePopup();
        });
        // When footnotes unlink is clicked
        this.listenTo(formView, 'unlink', () => {
            this.removeSelectedFootnoteElement();
            this.closeFootnotePopup();
        });
        (0, ckeditor5_ui_1.clickOutsideHandler)({
            emitter: formView,
            activator: () => { var _a; return ((_a = this.balloon) === null || _a === void 0 ? void 0 : _a.visibleView) === formView; },
            contextElements: ((_a = this.balloon) === null || _a === void 0 ? void 0 : _a.view.element) ? [this.balloon.view.element] : [],
            callback: () => this.closeFootnotePopup()
        });
        return formView;
    }
    removeSelectedFootnoteElement() {
        // If footnoteModelElementToBeEdited is not null then it means
        // that a footnote widget was Shift+Clicked and needs to be removed
        if (this.footnoteModelElementToBeRemoved) {
            this.editor.model.change(() => {
                this.editor.model.deleteContent(this.editor.model.createSelection(this.footnoteModelElementToBeRemoved, 'on'));
            });
        }
    }
    onLeftClick(modalTitle, modalText) {
        this.editor.config.get(models_1.CONFIG_KEY).onLeftClick(modalTitle, modalText);
    }
    openFootnotePopup() {
        if (!this.formView) {
            return;
        }
        if (!this.balloon) {
            return;
        }
        this.setupEditModeFormView();
        this.balloon.add({
            view: this.formView,
            position: (0, utils_2.getBalloonPositionData)(this.editor)
        });
        this.formView.updateDropdown();
    }
    closeFootnotePopup() {
        var _a;
        if (!this.balloon) {
            return;
        }
        if (!((_a = this.formView) === null || _a === void 0 ? void 0 : _a.unlinkButtonView)) {
            return;
        }
        this.footnoteModelElementToBeRemoved = null;
        this.selectedFootnote = null;
        this.footnoteToEdit = null;
        this.formView.unlinkButtonView.isVisible = false;
        this.formView.dropdownView.buttonView.label = models_1.DEFAULT_DROPDOWN_LABEL;
        this.balloon.remove(this.formView);
        this.editor.editing.view.focus();
    }
    setupEditModeFormView() {
        if (!this.formView ||
            !this.formView.unlinkButtonView ||
            !this.balloon ||
            !this.footnoteModelElementToBeRemoved) {
            return;
        }
        const footnoteId = Number(this.footnoteModelElementToBeRemoved.getAttribute(models_1.FOOTNOTE_ID_KEY));
        if (!footnoteId) {
            return;
        }
        const footnote = (0, utils_1.getFootnotes)(this.editor).find(x => x.footnoteId === footnoteId);
        if (!footnote) {
            return;
        }
        this.formView.unlinkButtonView.isVisible = true;
        this.footnoteToEdit = footnote;
        this.selectedFootnote = footnote;
        this.formView.dropdownView.buttonView.label = (0, utils_1.formatFootnote)(footnote);
    }
}
exports.default = FootnoteUI;
