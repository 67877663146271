"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TreatyTypes = exports.hyperlinkToolbarIcon = exports.REMOVE_HYPERLINK_ID = exports.ADD_HYPERLINK_ID = exports.TAX_MATRICES_ID = exports.DEFAULT_RULE_LABEL = exports.DEFAULT_SECTION_SUBSECTION_LABEL = exports.DEFAULT_SUBSECTION_LABEL = exports.DEFAULT_SECTION_LABEL = exports.DEFAULT_COUNTRY_LABEL = exports.HYPERLINK_ATTRIBUTE_KEY = exports.INTERNAL_HYPERLINK_MODEL_ID = exports.CONFIG_KEY = void 0;
exports.CONFIG_KEY = 'hyperlink';
exports.INTERNAL_HYPERLINK_MODEL_ID = 'hyperlink';
exports.HYPERLINK_ATTRIBUTE_KEY = 'internalHyperlink';
exports.DEFAULT_COUNTRY_LABEL = 'Country';
exports.DEFAULT_SECTION_LABEL = 'Section';
exports.DEFAULT_SUBSECTION_LABEL = 'Subsection';
exports.DEFAULT_SECTION_SUBSECTION_LABEL = 'Section - Subsection';
exports.DEFAULT_RULE_LABEL = 'Rule';
exports.TAX_MATRICES_ID = 'tax-matrices';
exports.ADD_HYPERLINK_ID = 'addHyperlink';
exports.REMOVE_HYPERLINK_ID = 'removeHyperlink';
exports.hyperlinkToolbarIcon = 'M3.9 12c0-1.71 1.39-3.1 3.1-3.1h4V7H7c-2.76 0-5 2.24-5 5s2.24 5 5 5h4v-1.9H7c-1.71 0-3.1-1.39-3.1-3.1zM8 13h8v-2H8v2zm9-6h-4v1.9h4c1.71 0 3.1 1.39 3.1 3.1s-1.39 3.1-3.1 3.1h-4V17h4c2.76 0 5-2.24 5-5s-2.24-5-5-5z';
var TreatyTypes;
(function (TreatyTypes) {
    TreatyTypes[TreatyTypes["Treaty"] = 1] = "Treaty";
    TreatyTypes[TreatyTypes["NonTreaty"] = 2] = "NonTreaty";
    TreatyTypes[TreatyTypes["TIEA"] = 3] = "TIEA";
    TreatyTypes[TreatyTypes["TaxHaven"] = 4] = "TaxHaven";
    TreatyTypes[TreatyTypes["EastAfricanCommunity"] = 5] = "EastAfricanCommunity";
    TreatyTypes[TreatyTypes["GulfCooperationCouncil"] = 6] = "GulfCooperationCouncil";
    TreatyTypes[TreatyTypes["MutualAgreement"] = 7] = "MutualAgreement";
    TreatyTypes[TreatyTypes["WhiteList"] = 8] = "WhiteList";
    TreatyTypes[TreatyTypes["NonTreatyFITMS"] = 9] = "NonTreatyFITMS";
    TreatyTypes[TreatyTypes["TIEAFITMS"] = 10] = "TIEAFITMS";
    TreatyTypes[TreatyTypes["TaxHavenFITMS"] = 11] = "TaxHavenFITMS";
    TreatyTypes[TreatyTypes["EastAfricanCommunityFITMS"] = 12] = "EastAfricanCommunityFITMS";
    TreatyTypes[TreatyTypes["GulfCooperationCouncilFITMS"] = 13] = "GulfCooperationCouncilFITMS";
    TreatyTypes[TreatyTypes["MutualAgreementFITMS"] = 14] = "MutualAgreementFITMS";
    TreatyTypes[TreatyTypes["WhiteListFITMS"] = 15] = "WhiteListFITMS";
    TreatyTypes[TreatyTypes["TreatyWhiteList"] = 16] = "TreatyWhiteList";
})(TreatyTypes = exports.TreatyTypes || (exports.TreatyTypes = {}));
