"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HyperlinkTextFormView = void 0;
const ckeditor5_core_1 = require("@ckeditor/ckeditor5-core");
const ckeditor5_ui_1 = require("@ckeditor/ckeditor5-ui");
const div_view_1 = require("../shared/div-view");
const utils_1 = require("../shared/utils");
const models_1 = require("../shared/models");
class HyperlinkTextFormView extends ckeditor5_ui_1.View {
    constructor(locale) {
        super(locale);
        this.savedValue = '';
        this.textView = (0, utils_1.createInputText)(locale, 'Link URL');
        const saveButtonView = (0, utils_1.createButtonWithIcon)('Save', ckeditor5_core_1.icons.check, 'ck-button-save');
        saveButtonView.delegate('execute').to(this, 'save');
        saveButtonView.bind('isEnabled').to(this.textView, 'errorText', (errorText) => !errorText);
        this.unlinkButtonView = (0, utils_1.createButtonWithIcon)('Unlink', (0, utils_1.wrapInSvgElement)(models_1.unlinkIcon), 'ck-button-unlink');
        this.unlinkButtonView.delegate('execute').to(this, 'unlink');
        this.unlinkButtonView.isVisible = false;
        const cancelButtonView = (0, utils_1.createButtonWithIcon)('Cancel', ckeditor5_core_1.icons.cancel, 'ck-button-cancel');
        cancelButtonView.delegate('execute').to(this, 'cancel');
        this.titleView = new div_view_1.DivView(locale, ['ck', 'ck-hyperlink-title'], ['Insert Hyperlink']);
        this.childViews = this.createCollection([
            this.titleView,
            new div_view_1.DivView(locale, ['ck', 'ck-external-hyperlink-input'], [this.textView]),
            new div_view_1.DivView(locale, ['ck', 'ck-hyperlink-buttons'], [saveButtonView, this.unlinkButtonView, cancelButtonView])
        ]);
        this.setTemplate({
            tag: 'form',
            attributes: {
                class: ['ck', 'ck-hyperlink-form'],
                tabindex: '-1'
            },
            children: this.childViews
        });
    }
    render() {
        super.render();
    }
    setTitle(title) {
        if (!this.titleView.element) {
            return;
        }
        this.titleView.element.textContent = title;
    }
    setupForm(initialValue) {
        this.textView.fieldView.value = initialValue || '';
        this.setTitle(initialValue ? 'Edit Hyperlink' : 'Insert Hyperlink');
        this.unlinkButtonView.isVisible = !!initialValue;
        this.savedValue = initialValue || '';
    }
}
exports.HyperlinkTextFormView = HyperlinkTextFormView;
