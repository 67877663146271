import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor.js';
import Alignment from '@ckeditor/ckeditor5-alignment/src/alignment.js';
import BlockQuote from '@ckeditor/ckeditor5-block-quote/src/blockquote.js';
import Bold from '@ckeditor/ckeditor5-basic-styles/src/bold.js';
import Superscript from '@ckeditor/ckeditor5-basic-styles/src/superscript';
import Comments from '@ckeditor/ckeditor5-comments/src/comments.js';
import Essentials from '@ckeditor/ckeditor5-essentials/src/essentials.js';
import FontSize from '@ckeditor/ckeditor5-font/src/fontsize.js';
import GeneralHtmlSupport from '@ckeditor/ckeditor5-html-support/src/generalhtmlsupport.js';
import Heading from '@ckeditor/ckeditor5-heading/src/heading.js';
import Highlight from '@ckeditor/ckeditor5-highlight/src/highlight.js';
import Indent from '@ckeditor/ckeditor5-indent/src/indent.js';
import Italic from '@ckeditor/ckeditor5-basic-styles/src/italic.js';
import List from '@ckeditor/ckeditor5-list/src/list.js';
import Paragraph from '@ckeditor/ckeditor5-paragraph/src/paragraph.js';
import RestrictedEditingMode from '@ckeditor/ckeditor5-restricted-editing/src/restrictededitingmode.js';
import Strikethrough from '@ckeditor/ckeditor5-basic-styles/src/strikethrough.js';
import Table from '@ckeditor/ckeditor5-table/src/table.js';
import TableColumnResizeEditing from '@ckeditor/ckeditor5-table/src/tablecolumnresize/tablecolumnresizeediting';
import TableCellWidthEditing from '@ckeditor/ckeditor5-table/src/tablecellwidth/tablecellwidthediting';
import TableColumnResize from '@ckeditor/ckeditor5-table/src/tablecolumnresize.js';
import TableProperties from '@ckeditor/ckeditor5-table/src/tableproperties.js';
import TablePropertiesUI from '@ckeditor/ckeditor5-table/src/tableproperties/tablepropertiesui.js';
import TablePropertiesEditing from '@ckeditor/ckeditor5-table/src/tableproperties/tablepropertiesediting.js';
import TableCellProperties from '@ckeditor/ckeditor5-table/src/tablecellproperties.js';
import TableToolbar from '@ckeditor/ckeditor5-table/src/tabletoolbar.js';
import TrackChanges from '@ckeditor/ckeditor5-track-changes/src/trackchanges.js';
import TrackChangesData from '@ckeditor/ckeditor5-track-changes/src/trackchangesdata.js';
import Underline from '@ckeditor/ckeditor5-basic-styles/src/underline.js';
import Footnote from './plugins/build/footnote/footnote.js';
import Hyperlink from './plugins/build/hyperlink/hyperlink.js';

import SuggestionThreadView from '@ckeditor/ckeditor5-track-changes/src/ui/view/suggestionthreadview';


class Editor extends ClassicEditor {}

// Plugins to include in the build.
Editor.builtinPlugins = [
  Alignment,
  BlockQuote,
  Bold,
  Comments,
  Essentials,
  FontSize,
  GeneralHtmlSupport,
  Heading,
  Highlight,
  Indent,
  Italic,
  List,
  Paragraph,
  RestrictedEditingMode,
  Strikethrough,
  Table,
  TableColumnResizeEditing,
  TableCellWidthEditing,
  TableColumnResize,
  TableToolbar,
  TableProperties,
  TablePropertiesUI,
  TablePropertiesEditing,
  TableCellProperties,
  TrackChanges,
  TrackChangesData,
  Underline,
  Superscript,
  Footnote,
  Hyperlink
];

// Editor configuration.
Editor.defaultConfig = {
	toolbar: {
		items: [
			'undo',
			'redo',
			'|',
			'heading',
			'bold',
			'italic',
			'underline',
			'strikethrough',
			'highlight',
			'fontSize',
			'|',
			'alignment',
			'bulletedList',
			'numberedList',
			'indent',
			'outdent',
			'|',
			'blockQuote',
			'link',
			'insertTable',
			'|',
			'trackChanges',
			'comment',
			'restrictedEditing',
			'trackChangesData'
		]
	},
	language: 'en',
	table: {
		contentToolbar: [
			'tableColumn',
			'tableRow',
			'mergeTableCells',
			'tableProperties',
			'tableCellProperties'
		],
		tableToolbar: [
			'comment'
		]
	},
	comments: {
		editorConfig: {
			extraPlugins: [
				Bold,
				Italic,
				List
			]
		}
	}
};

export default { Editor, SuggestionThreadView };
