"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.HyperlinkUI = void 0;
const ckeditor5_core_1 = require("@ckeditor/ckeditor5-core");
const hyperlink_external_form_view_1 = require("./hyperlink-external-form-view");
const ckeditor5_ui_1 = require("@ckeditor/ckeditor5-ui");
const ckeditor5_widget_1 = require("@ckeditor/ckeditor5-widget");
const utils_1 = require("../shared/utils");
const internal_hyperlink_form_view_1 = require("./internal-hyperlink-form-view");
const models_1 = require("./models");
const utils_2 = require("./utils");
class HyperlinkUI extends ckeditor5_core_1.Plugin {
    constructor() {
        super(...arguments);
        this.balloon = null;
        this.textFormView = null;
        this.internalHyperlinkFormView = null;
        this.addHyperlinkCommand = null;
        this.removeHyperlinkCommand = null;
        this.selectedCountry = null;
        this.selectedSection = null;
        this.selectedInvestorCountry = null;
        this.selectedSubsection = null;
        this.selectedTreatyType = null;
        this.selectedRule = null;
        this.changeWasMade = false;
        this.dropdownView = null;
        this.externalHyperlinkButton = null;
        this.sectionNameIdToName = (0, utils_2.getSectionNameIdToNameDictionary)(this.editor);
    }
    static get pluginName() {
        return 'HyperlinkUI';
    }
    static get requires() {
        return [ckeditor5_ui_1.ContextualBalloon];
    }
    init() {
        if (!(0, utils_1.isEditorWithUI)(this.editor)) {
            return;
        }
        this.addHyperlinkCommand = this.editor.commands.get(models_1.ADD_HYPERLINK_ID);
        this.removeHyperlinkCommand = this.editor.commands.get(models_1.REMOVE_HYPERLINK_ID);
        this.balloon = this.editor.plugins.get(ckeditor5_ui_1.ContextualBalloon);
        this.internalHyperlinkFormView = new internal_hyperlink_form_view_1.InternalHyperlinkFormView(this.editor.locale, this.editor);
        this.textFormView = new hyperlink_external_form_view_1.HyperlinkTextFormView(this.editor.locale);
        this.internalHyperlinkFormView.rulesDropdownView.buttonView.isVisible = false;
        this.setupCountryDropdownView(this.internalHyperlinkFormView);
        this.setupSectionSubsectionDropdownView(this.internalHyperlinkFormView);
        this.setupSubsectionDropdownView(this.internalHyperlinkFormView);
        this.setupRulesDropdownView(this.internalHyperlinkFormView);
        this.setupSubmitButton(this.internalHyperlinkFormView);
        this.setupCancelButton(this.internalHyperlinkFormView);
        this.setupUnlinkButton(this.internalHyperlinkFormView);
        this.setupClickOutsideHandler(this.internalHyperlinkFormView);
        this.setupTextFormView(this.textFormView);
        this._enableBalloonActivators();
        this.editor.ui.componentFactory.add(models_1.INTERNAL_HYPERLINK_MODEL_ID, locale => {
            const onlyExternalConfig = this.getOnlyExternalHyperlink();
            if (onlyExternalConfig) {
                const button = new ckeditor5_ui_1.ButtonView();
                button.set({
                    label: onlyExternalConfig.label || 'Insert External Hyperlink',
                    icon: (0, utils_1.wrapInSvgElement)(models_1.hyperlinkToolbarIcon, [0, 0, 23, 23]),
                    tooltip: true,
                });
                button.on('execute', () => {
                    this.openHyperlinkPopup('external');
                });
                this.externalHyperlinkButton = button;
                return button;
            }
            const dropdownView = (0, ckeditor5_ui_1.createDropdown)(locale);
            dropdownView.buttonView.set({
                label: 'Insert Internal or External Hyperlinks',
                icon: (0, utils_1.wrapInSvgElement)(models_1.hyperlinkToolbarIcon, [0, 0, 23, 23]),
                tooltip: true
            });
            const internalButton = (0, utils_1.createButtonWithText)('Insert Internal Hyperlink', 'ck-insert-internal-hyperlink');
            const externalButton = (0, utils_1.createButtonWithText)('Insert External Hyperlink', 'ck-insert-external-hyperlink');
            internalButton.on('execute', () => {
                this.openHyperlinkPopup('internal');
            });
            externalButton.on('execute', () => {
                this.openHyperlinkPopup('external');
            });
            (0, ckeditor5_ui_1.addToolbarToDropdown)(dropdownView, [internalButton, externalButton]);
            dropdownView.toolbarView.isVertical = true;
            this.dropdownView = dropdownView;
            return dropdownView;
        });
    }
    getOnlyExternalHyperlink() {
        return this.editor.config.get(models_1.CONFIG_KEY).onlyExternal;
    }
    setupCountryDropdownView(formView) {
        this.listenTo(formView.countryDropdownView, 'execute', (evt) => __awaiter(this, void 0, void 0, function* () {
            var _a;
            this.changeWasMade = true;
            const country = evt.source.country;
            this.selectedCountry = country !== null && country !== void 0 ? country : null;
            if (!country) {
                return;
            }
            formView.countryDropdownView.buttonView.label = country.countryName;
            formView.sectionSubsectionDropdownView.buttonView.label = models_1.DEFAULT_SECTION_LABEL;
            (0, utils_1.clearDropdownList)(formView.sectionSubsectionDropdownView);
            const sections = yield this.getSections(Number(country.countryId)).catch((error) => {
                console.error("An error occurred:", error);
            });
            const sectionTreatyTypes = [];
            if (!sections) {
                return;
            }
            for (const section of sections) {
                if (Number(section.sectionId) === 1) {
                    const treatyTypes = yield this.getFilteredTreatyTypes(Number(section.sectionId), section.sectionNameId, country.countryId).catch((error) => {
                        console.error("An error occurred:", error);
                    });
                    if (treatyTypes) {
                        for (const treatyType of treatyTypes) {
                            sectionTreatyTypes.push({
                                name: section.name + ' - ' + treatyType.name,
                                section: section,
                                treatyType: treatyType
                            });
                        }
                    }
                }
                else {
                    sectionTreatyTypes.push({
                        name: section.name,
                        section: section,
                        treatyType: null
                    });
                }
            }
            (0, utils_1.updateDropdownList)(formView.sectionSubsectionDropdownView, (0, utils_2.mapSectionTreatyTypesToCollection)(sectionTreatyTypes));
            if (sectionTreatyTypes.length === 0) {
                this.selectedSection = null;
            }
            else {
                const firstCombination = sectionTreatyTypes[0];
                this.selectedSection = firstCombination.section;
                formView.sectionSubsectionDropdownView.buttonView.label = (_a = firstCombination.name) !== null && _a !== void 0 ? _a : models_1.DEFAULT_SECTION_LABEL;
            }
            this.editor.editing.view.focus();
        }));
    }
    setupSectionSubsectionDropdownView(formView) {
        this.listenTo(formView.sectionSubsectionDropdownView, 'execute', (evt) => __awaiter(this, void 0, void 0, function* () {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
            this.changeWasMade = true;
            const subsectionsInvestorCountries = [];
            const section = (_a = evt.source.sectionTreatyType) === null || _a === void 0 ? void 0 : _a.section;
            if (!section) {
                return;
            }
            this.selectedSection = section;
            this.selectedSubsection = null;
            this.selectedInvestorCountry = null;
            if (Number((_c = (_b = evt.source.sectionTreatyType) === null || _b === void 0 ? void 0 : _b.section) === null || _c === void 0 ? void 0 : _c.sectionId) !== 1) {
                formView.rulesDropdownView.buttonView.isVisible = false;
                this.selectedTreatyType = null;
                formView.sectionSubsectionDropdownView.buttonView.label = section.name;
                formView.subsectionDropdownView.buttonView.label = models_1.DEFAULT_SUBSECTION_LABEL;
                (0, utils_1.clearDropdownList)(formView.subsectionDropdownView);
                const subsections = yield this.getSubsections(Number(section.sectionId), section.sectionNameId, Number(section.countryId)).catch((error) => {
                    console.error("An error occurred:", error);
                });
                if (!subsections) {
                    return;
                }
                for (const subsection of subsections) {
                    subsectionsInvestorCountries.push({
                        name: subsection.subsectionDescription,
                        subsection: subsection !== null && subsection !== void 0 ? subsection : null,
                        investorCountry: null
                    });
                }
                if (subsectionsInvestorCountries.length === 0) {
                    this.selectedSubsection = null;
                }
                else {
                    const firstSubsection = subsectionsInvestorCountries[0].subsection;
                    this.selectedSubsection = firstSubsection;
                    formView.subsectionDropdownView.buttonView.label = (_d = firstSubsection === null || firstSubsection === void 0 ? void 0 : firstSubsection.subsectionDescription) !== null && _d !== void 0 ? _d : models_1.DEFAULT_SUBSECTION_LABEL;
                }
            }
            else {
                formView.rulesDropdownView.buttonView.isVisible = true;
                const treatyTYpe = (_e = evt.source.sectionTreatyType) === null || _e === void 0 ? void 0 : _e.treatyType;
                this.selectedTreatyType = treatyTYpe !== null && treatyTYpe !== void 0 ? treatyTYpe : null;
                formView.sectionSubsectionDropdownView.buttonView.label = section.name + ' - ' + (treatyTYpe === null || treatyTYpe === void 0 ? void 0 : treatyTYpe.name);
                formView.subsectionDropdownView.buttonView.label = models_1.DEFAULT_SUBSECTION_LABEL;
                formView.rulesDropdownView.buttonView.label = models_1.DEFAULT_RULE_LABEL;
                (0, utils_1.clearDropdownList)(formView.subsectionDropdownView);
                (0, utils_1.clearDropdownList)(formView.rulesDropdownView);
                let treatyTypeId = (_f = treatyTYpe === null || treatyTYpe === void 0 ? void 0 : treatyTYpe.treatyTypeId) !== null && _f !== void 0 ? _f : 0;
                if ((0, utils_2.isBuketTreatyType)(treatyTypeId) || treatyTypeId === 0) {
                    subsectionsInvestorCountries.push({
                        name: 'N/A',
                        subsection: null,
                        investorCountry: null
                    });
                    const rules = [];
                    const filters = {
                        pageIndex: 1,
                        pageSize: 1000,
                        investmentCountryId: (_g = this.selectedSection) === null || _g === void 0 ? void 0 : _g.countryId,
                        investorCountryId: '0',
                        treatyTypeId: (_j = (_h = this.selectedTreatyType) === null || _h === void 0 ? void 0 : _h.treatyTypeId) !== null && _j !== void 0 ? _j : '0',
                    };
                    const incomeTypeRules = yield this.getRulesInstrumentCategories(filters).catch((error) => {
                        console.error("An error occurred:", error);
                    });
                    if (!incomeTypeRules) {
                        return;
                    }
                    const beneficialOwnerRules = yield this.getRulesFundTypes(filters).catch((error) => {
                        console.error("An error occurred:", error);
                    });
                    if (!beneficialOwnerRules) {
                        return;
                    }
                    for (const incomeTypeRule of incomeTypeRules.list) {
                        rules.push({
                            ruleName: 'Income Type - ' + incomeTypeRule.ruleId + ' - ' + incomeTypeRule.ruleTitle,
                            ruleId: incomeTypeRule.ruleId,
                            ruleDescription: incomeTypeRule.rule
                        });
                    }
                    for (const beneficialOwnerRule of beneficialOwnerRules.list) {
                        rules.push({
                            ruleName: 'Beneficial Owner - ' + beneficialOwnerRule.ruleId + ' - ' + beneficialOwnerRule.ruleTitle,
                            ruleId: beneficialOwnerRule.ruleId,
                            ruleDescription: beneficialOwnerRule.rule
                        });
                    }
                    (0, utils_1.updateDropdownList)(formView.rulesDropdownView, (0, utils_2.mapRulesToCollection)(rules));
                    if (rules.length === 0) {
                        this.selectedRule = null;
                    }
                    else {
                        const firstRule = rules[0];
                        this.selectedRule = firstRule;
                        formView.rulesDropdownView.buttonView.label = (_k = firstRule === null || firstRule === void 0 ? void 0 : firstRule.ruleName) !== null && _k !== void 0 ? _k : models_1.DEFAULT_RULE_LABEL;
                    }
                }
                else {
                    const investorCountries = yield this.getinvestorCountries(Number(section.countryId), treatyTypeId).catch((error) => {
                        console.error("An error occurred:", error);
                    });
                    if (!investorCountries) {
                        return;
                    }
                    for (const investorCountry of investorCountries) {
                        subsectionsInvestorCountries.push({
                            name: investorCountry.investorCountryName,
                            subsection: null,
                            investorCountry: investorCountry !== null && investorCountry !== void 0 ? investorCountry : null
                        });
                    }
                }
                if (subsectionsInvestorCountries.length === 0) {
                    this.selectedInvestorCountry = null;
                }
                else {
                    const firstInvestorCountry = subsectionsInvestorCountries[0].investorCountry;
                    this.selectedInvestorCountry = firstInvestorCountry;
                    formView.subsectionDropdownView.buttonView.label = (0, utils_2.isBuketTreatyType)(treatyTypeId) || treatyTypeId === 0 ?
                        'N/A' :
                        (_l = firstInvestorCountry === null || firstInvestorCountry === void 0 ? void 0 : firstInvestorCountry.investorCountryName) !== null && _l !== void 0 ? _l : models_1.DEFAULT_SUBSECTION_LABEL;
                }
            }
            (0, utils_1.updateDropdownList)(formView.subsectionDropdownView, (0, utils_2.mapSubsectionInvestorCountryToCollection)(subsectionsInvestorCountries));
            this.editor.editing.view.focus();
        }));
    }
    setupSubsectionDropdownView(formView) {
        this.listenTo(formView.subsectionDropdownView, 'execute', (evt) => __awaiter(this, void 0, void 0, function* () {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
            this.changeWasMade = true;
            const subsection = (_a = evt.source.subsectionInvestorCountry) === null || _a === void 0 ? void 0 : _a.subsection;
            const investorCountry = (_b = evt.source.subsectionInvestorCountry) === null || _b === void 0 ? void 0 : _b.investorCountry;
            (0, utils_1.clearDropdownList)(formView.rulesDropdownView);
            if (subsection !== null) {
                if (!subsection) {
                    return;
                }
                this.selectedSubsection = subsection;
                this.selectedInvestorCountry = null;
                formView.subsectionDropdownView.buttonView.label = (_c = subsection === null || subsection === void 0 ? void 0 : subsection.subsectionDescription) !== null && _c !== void 0 ? _c : models_1.DEFAULT_SUBSECTION_LABEL;
                this.editor.editing.view.focus();
            }
            else {
                this.selectedSubsection = null;
                this.selectedInvestorCountry = investorCountry !== null && investorCountry !== void 0 ? investorCountry : null;
                if (!investorCountry) {
                    formView.subsectionDropdownView.buttonView.label = (_e = (_d = evt.source.subsectionInvestorCountry) === null || _d === void 0 ? void 0 : _d.name) !== null && _e !== void 0 ? _e : models_1.DEFAULT_SUBSECTION_LABEL;
                }
                else {
                    formView.subsectionDropdownView.buttonView.label = (_f = investorCountry === null || investorCountry === void 0 ? void 0 : investorCountry.investorCountryName) !== null && _f !== void 0 ? _f : models_1.DEFAULT_SUBSECTION_LABEL;
                }
                const rules = [];
                const filters = {
                    pageIndex: 1,
                    pageSize: 1000,
                    investmentCountryId: (_g = this.selectedSection) === null || _g === void 0 ? void 0 : _g.countryId,
                    investorCountryId: (_j = (_h = this.selectedInvestorCountry) === null || _h === void 0 ? void 0 : _h.investorCountryId) !== null && _j !== void 0 ? _j : '0',
                    treatyTypeId: (_l = (_k = this.selectedTreatyType) === null || _k === void 0 ? void 0 : _k.treatyTypeId) !== null && _l !== void 0 ? _l : '0',
                };
                const incomeTypeRules = yield this.getRulesInstrumentCategories(filters).catch((error) => {
                    console.error("An error occurred:", error);
                });
                if (!incomeTypeRules) {
                    return;
                }
                const beneficialOwnerRules = yield this.getRulesFundTypes(filters).catch((error) => {
                    console.error("An error occurred:", error);
                });
                if (!beneficialOwnerRules) {
                    return;
                }
                for (const incomeTypeRule of incomeTypeRules.list) {
                    rules.push({
                        ruleName: 'Income Type - ' + incomeTypeRule.ruleId + ' - ' + incomeTypeRule.ruleTitle,
                        ruleId: incomeTypeRule.ruleId,
                        ruleDescription: incomeTypeRule.rule
                    });
                }
                for (const beneficialOwnerRule of beneficialOwnerRules.list) {
                    rules.push({
                        ruleName: 'Beneficial Owner - ' + beneficialOwnerRule.ruleId + ' - ' + beneficialOwnerRule.ruleTitle,
                        ruleId: beneficialOwnerRule.ruleId,
                        ruleDescription: beneficialOwnerRule.rule
                    });
                }
                (0, utils_1.updateDropdownList)(formView.rulesDropdownView, (0, utils_2.mapRulesToCollection)(rules));
                if (rules.length === 0) {
                    this.selectedRule = null;
                }
                else {
                    const firstRule = rules[0];
                    this.selectedRule = firstRule;
                    formView.rulesDropdownView.buttonView.label = (_m = firstRule === null || firstRule === void 0 ? void 0 : firstRule.ruleName) !== null && _m !== void 0 ? _m : models_1.DEFAULT_RULE_LABEL;
                }
                this.editor.editing.view.focus();
            }
        }));
    }
    setupRulesDropdownView(formView) {
        this.listenTo(formView.rulesDropdownView, 'execute', (evt) => __awaiter(this, void 0, void 0, function* () {
            var _a;
            this.changeWasMade = true;
            const rule = evt.source.rule;
            if (!rule) {
                return;
            }
            this.selectedRule = rule;
            formView.rulesDropdownView.buttonView.label = (_a = rule === null || rule === void 0 ? void 0 : rule.ruleName) !== null && _a !== void 0 ? _a : models_1.DEFAULT_RULE_LABEL;
            this.editor.editing.view.focus();
        }));
    }
    setupSubmitButton(formView) {
        this.listenTo(formView, 'submit', () => {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
            if (!this.changeWasMade) {
                this.closeInternalHyperlinkPopup();
            }
            if (!this.selectedSection) {
                return;
            }
            const url = this.createUrl(this.selectedCountry, this.selectedSection.sectionNameId, (_b = (_a = this.selectedSubsection) === null || _a === void 0 ? void 0 : _a.subsectionId) !== null && _b !== void 0 ? _b : null, (_d = (_c = this.selectedSubsection) === null || _c === void 0 ? void 0 : _c.subsectionDescription) !== null && _d !== void 0 ? _d : null, (_e = this.selectedInvestorCountry) !== null && _e !== void 0 ? _e : null, (_f = this.selectedTreatyType) !== null && _f !== void 0 ? _f : null, (_h = (_g = this.selectedRule) === null || _g === void 0 ? void 0 : _g.ruleId) !== null && _h !== void 0 ? _h : null);
            if (!url) {
                return;
            }
            (_j = this.addHyperlinkCommand) === null || _j === void 0 ? void 0 : _j.execute({
                internal: true,
                url,
                section: this.selectedSection,
                subsectionId: (_k = this.selectedSubsection) === null || _k === void 0 ? void 0 : _k.subsectionId,
                subsectionDescription: (_l = this.selectedSubsection) === null || _l === void 0 ? void 0 : _l.subsectionDescription
            });
            this.closeInternalHyperlinkPopup();
        });
    }
    setupCancelButton(formView) {
        this.listenTo(formView, 'cancel', () => {
            this.closeInternalHyperlinkPopup();
        });
    }
    setupUnlinkButton(formView) {
        this.listenTo(formView, 'unlink', () => {
            var _a, _b, _c, _d;
            const attributeJsonValue = (_b = (_a = this.editor.model.document.selection.getAttribute(models_1.HYPERLINK_ATTRIBUTE_KEY)) === null || _a === void 0 ? void 0 : _a.toString()) !== null && _b !== void 0 ? _b : '';
            const url = JSON.parse(attributeJsonValue).href;
            (_c = this.removeHyperlinkCommand) === null || _c === void 0 ? void 0 : _c.execute({
                internal: true,
                url,
                section: this.selectedSection,
                subsectionDescription: (_d = this.selectedSubsection) === null || _d === void 0 ? void 0 : _d.subsectionDescription
            });
            this.closeInternalHyperlinkPopup();
        });
    }
    setupClickOutsideHandler(formView) {
        var _a;
        (0, ckeditor5_ui_1.clickOutsideHandler)({
            emitter: formView,
            activator: () => { var _a; return ((_a = this.balloon) === null || _a === void 0 ? void 0 : _a.visibleView) === formView; },
            contextElements: ((_a = this.balloon) === null || _a === void 0 ? void 0 : _a.view.element)
                ? [this.balloon.view.element]
                : [],
            callback: () => this.closeInternalHyperlinkPopup(),
        });
    }
    setupTextFormView(hyperlinkTextFormView) {
        var _a;
        (0, ckeditor5_ui_1.clickOutsideHandler)({
            emitter: hyperlinkTextFormView,
            activator: () => { var _a; return ((_a = this.balloon) === null || _a === void 0 ? void 0 : _a.visibleView) === hyperlinkTextFormView; },
            contextElements: ((_a = this.balloon) === null || _a === void 0 ? void 0 : _a.view.element)
                ? [this.balloon.view.element]
                : [],
            callback: () => this.closeExternalHyperlinkPopup(),
        });
        this.listenTo(hyperlinkTextFormView, 'save', (_evt) => __awaiter(this, void 0, void 0, function* () {
            var _b;
            let value = hyperlinkTextFormView.textView.fieldView.element
                .value;
            if (value === hyperlinkTextFormView.savedValue) {
                this.closeExternalHyperlinkPopup();
                return;
            }
            const isLinkValid = yield this.validateExternalHyperlink(value);
            if (!isLinkValid) {
                hyperlinkTextFormView.textView.errorText = 'Invalid URL';
                return;
            }
            this.editor.editing.view.focus();
            (_b = this.addHyperlinkCommand) === null || _b === void 0 ? void 0 : _b.execute({ internal: false, url: value, externalLink: value });
            this.closeExternalHyperlinkPopup();
        }));
        this.listenTo(hyperlinkTextFormView, 'unlink', () => {
            var _a, _b, _c;
            const attributeJsonValue = (_b = (_a = this.editor.model.document.selection.getAttribute(models_1.HYPERLINK_ATTRIBUTE_KEY)) === null || _a === void 0 ? void 0 : _a.toString()) !== null && _b !== void 0 ? _b : '';
            const url = JSON.parse(attributeJsonValue).href;
            (_c = this.removeHyperlinkCommand) === null || _c === void 0 ? void 0 : _c.execute({
                internal: false,
                url,
                externalLink: url,
            });
            this.closeExternalHyperlinkPopup();
        });
        this.listenTo(hyperlinkTextFormView, 'cancel', () => {
            this.closeExternalHyperlinkPopup();
        });
    }
    openHyperlinkPopup(type, extLinkValue) {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.balloon) {
                return;
            }
            if (type === 'external') {
                if (!this.textFormView) {
                    return;
                }
                this.textFormView.setupForm(extLinkValue);
                (_a = this.balloon) === null || _a === void 0 ? void 0 : _a.add({
                    view: this.textFormView,
                    position: (0, utils_1.getBalloonPositionData)(this.editor),
                });
            }
            else {
                if (!this.internalHyperlinkFormView) {
                    return;
                }
                const countries = (0, utils_2.getCountries)(this.editor);
                (0, utils_1.updateDropdownList)(this.internalHyperlinkFormView.countryDropdownView, (0, utils_2.mapCountryToCollection)(countries));
                (_b = this.balloon) === null || _b === void 0 ? void 0 : _b.add({
                    view: this.internalHyperlinkFormView,
                    position: (0, utils_1.getBalloonPositionData)(this.editor),
                });
            }
        });
    }
    closeExternalHyperlinkPopup() {
        if (!this.balloon) {
            return;
        }
        if (!this.textFormView) {
            return;
        }
        this.textFormView.textView.fieldView.value = '';
        this.textFormView.textView.errorText = null;
        this.textFormView.element.reset();
        this.balloon.remove(this.textFormView);
        this.editor.editing.view.focus();
    }
    closeInternalHyperlinkPopup() {
        if (!this.balloon) {
            return;
        }
        if (!this.internalHyperlinkFormView) {
            return;
        }
        this.selectedCountry = null;
        this.selectedSection = null;
        this.selectedSubsection = null;
        this.changeWasMade = false;
        this.internalHyperlinkFormView.countryDropdownView.buttonView.label = models_1.DEFAULT_COUNTRY_LABEL;
        this.internalHyperlinkFormView.sectionSubsectionDropdownView.buttonView.label = models_1.DEFAULT_SECTION_LABEL;
        this.internalHyperlinkFormView.subsectionDropdownView.buttonView.label = models_1.DEFAULT_SUBSECTION_LABEL;
        this.internalHyperlinkFormView.rulesDropdownView.buttonView.label = models_1.DEFAULT_SECTION_LABEL;
        this.internalHyperlinkFormView.rulesDropdownView.buttonView.isVisible = false;
        this.internalHyperlinkFormView.unlinkButtonView.isVisible = false;
        (0, utils_1.clearDropdownList)(this.internalHyperlinkFormView.sectionSubsectionDropdownView);
        (0, utils_1.clearDropdownList)(this.internalHyperlinkFormView.subsectionDropdownView);
        this.balloon.remove(this.internalHyperlinkFormView);
        this.editor.editing.view.focus();
    }
    createUrl(countryId, sectionNameId, subsectionId, subsectionDescription, investorCountry, treatyType, ruleId) {
        return this.editor.config.get(models_1.CONFIG_KEY).createUrl(countryId, sectionNameId, subsectionId, subsectionDescription, investorCountry, treatyType, ruleId);
    }
    getSections(countryId) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.editor.config.get(models_1.CONFIG_KEY).getSections(countryId);
        });
    }
    getSubsections(sectionId, sectionNameId, countryId) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.editor.config.get(models_1.CONFIG_KEY).getSubsections(sectionId, sectionNameId, countryId);
        });
    }
    getTreatyTypes() {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.editor.config.get(models_1.CONFIG_KEY).getTreatyTypes();
        });
    }
    getFilteredTreatyTypes(sectionId, sectionNameId, countryId) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.editor.config.get(models_1.CONFIG_KEY).getFilteredTreatyTypes(sectionId, sectionNameId, countryId);
        });
    }
    getinvestorCountries(investmentCountryId, treatyTypeId) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.editor.config.get(models_1.CONFIG_KEY).getInvestorCountries(investmentCountryId, treatyTypeId);
        });
    }
    validateExternalHyperlink(link) {
        return this.editor.config.get(models_1.CONFIG_KEY).validateExternalHyperlink(link);
    }
    getRulesInstrumentCategories(filters) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.editor.config.get(models_1.CONFIG_KEY).getRulesInstrumentCategories(filters);
        });
    }
    getRulesFundTypes(filters) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.editor.config.get(models_1.CONFIG_KEY).getRulesFundTypes(filters);
        });
    }
    _enableBalloonActivators() {
        const editor = this.editor;
        const viewDocument = editor.editing.view.document;
        // Handle click on view document and show panel when selection is placed inside the link element.
        // Keep panel open until selection will be inside the same link element.
        this.listenTo(viewDocument, 'click', (_, event) => __awaiter(this, void 0, void 0, function* () {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
            const parentLink = this._getSelectedLinkElement();
            if (!parentLink) {
                return;
            }
            if (!event.domEvent.shiftKey && !event.domEvent.altKey) {
                return;
            }
            const countryId = Number((_a = (0, utils_2.getHyperlinkAttribute)(parentLink, 'country-id')) !== null && _a !== void 0 ? _a : '');
            if (countryId && this.internalHyperlinkFormView) { // if sectionId present link is internal
                const subsectionId = (_b = (0, utils_2.getHyperlinkAttribute)(parentLink, 'subsection')) !== null && _b !== void 0 ? _b : '';
                const sectionName = (_c = (0, utils_2.getHyperlinkAttribute)(parentLink, 'section-name')) !== null && _c !== void 0 ? _c : '';
                const sectionNameId = Number(this.sectionNameIdToName[sectionName]);
                const sections = yield this.getSections(countryId);
                const section = (_d = sections.find(x => Number(x.countryId) === countryId && x.sectionNameId === sectionNameId)) !== null && _d !== void 0 ? _d : null;
                const subsections = yield this.getSubsections(Number(section === null || section === void 0 ? void 0 : section.sectionId), sectionNameId, countryId);
                const subsection = (_e = subsections.find(x => x.subsectionId === subsectionId)) !== null && _e !== void 0 ? _e : null;
                const country = (_f = (0, utils_2.getCountries)(this.editor).find(x => x.countryId === countryId)) !== null && _f !== void 0 ? _f : null;
                this.selectedCountry = country;
                this.selectedSection = section;
                this.selectedSubsection = subsection;
                this.internalHyperlinkFormView.countryDropdownView.buttonView.label = (_h = (_g = this.selectedCountry) === null || _g === void 0 ? void 0 : _g.countryName) !== null && _h !== void 0 ? _h : '';
                this.internalHyperlinkFormView.sectionSubsectionDropdownView.buttonView.label = (_k = (_j = this.selectedSection) === null || _j === void 0 ? void 0 : _j.name) !== null && _k !== void 0 ? _k : '';
                this.internalHyperlinkFormView.subsectionDropdownView.buttonView.label = (_m = (_l = this.selectedSubsection) === null || _l === void 0 ? void 0 : _l.subsectionDescription) !== null && _m !== void 0 ? _m : '';
                (0, utils_1.updateDropdownList)(this.internalHyperlinkFormView.subsectionDropdownView, (0, utils_2.mapSubsectionsToCollection)(subsections));
                (0, utils_1.updateDropdownList)(this.internalHyperlinkFormView.rulesDropdownView, (0, utils_2.mapSectionsToCollection)(sections));
                this.internalHyperlinkFormView.unlinkButtonView.isVisible = true;
                this.openHyperlinkPopup('internal');
            }
            else if (this.textFormView) { // is external link
                const currentLink = (0, utils_2.getHyperlinkAttribute)(parentLink, 'href') || '';
                this.openHyperlinkPopup('external', currentLink);
            }
        }));
    }
    _getSelectedLinkElement() {
        const view = this.editor.editing.view;
        const selection = view.document.selection;
        const selectedElement = selection.getSelectedElement();
        // The selection is collapsed or some widget is selected (especially inline widget).
        if (selection.isCollapsed || selectedElement && (0, ckeditor5_widget_1.isWidget)(selectedElement)) {
            return findLinkElementAncestor(selection.getFirstPosition());
        }
        else {
            // The range for fully selected link is usually anchored in adjacent text nodes.
            // Trim it to get closer to the actual link element.
            const range = selection.getFirstRange().getTrimmed();
            const startLink = findLinkElementAncestor(range.start);
            const endLink = findLinkElementAncestor(range.end);
            if (!startLink || startLink != endLink) {
                return null;
            }
            // Check if the link element is fully selected.
            if (view.createRangeIn(startLink).getTrimmed().isEqual(range)) {
                return startLink;
            }
            else {
                return null;
            }
        }
    }
    toggleDropdownButton(isEnabled) {
        if (!this.dropdownView) {
            return;
        }
        this.dropdownView.isEnabled = isEnabled;
    }
    toggleExternalHyperlinkButton(isEnabled) {
        if (!this.externalHyperlinkButton) {
            return;
        }
        this.externalHyperlinkButton.isEnabled = isEnabled;
    }
}
exports.HyperlinkUI = HyperlinkUI;
function findLinkElementAncestor(position) {
    return position.getAncestors().find((ancestor) => (0, utils_2.isLinkElement)(ancestor)) || null;
}
