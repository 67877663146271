"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InternalHyperlinkFormView = void 0;
const ckeditor5_core_1 = require("@ckeditor/ckeditor5-core");
const ckeditor5_ui_1 = require("@ckeditor/ckeditor5-ui");
const ckeditor5_utils_1 = require("@ckeditor/ckeditor5-utils");
const div_view_1 = require("../shared/div-view");
const models_1 = require("../shared/models");
const utils_1 = require("../shared/utils");
const models_2 = require("./models");
const utils_2 = require("./utils");
class InternalHyperlinkFormView extends ckeditor5_ui_1.View {
    constructor(locale, editor) {
        super(locale);
        this.editor = editor;
        this.countryDropdownView = (0, ckeditor5_ui_1.createDropdown)(locale);
        this.countryDropdownView.buttonView.set({
            label: models_2.DEFAULT_COUNTRY_LABEL,
            withText: true
        });
        const currentCountry = (0, utils_2.getCountries)(this.editor);
        const countryCollection = currentCountry
            ? new ckeditor5_utils_1.Collection(currentCountry.map(s => this.createCountryDropdownDefinition(s))) : new ckeditor5_utils_1.Collection();
        (0, ckeditor5_ui_1.addListToDropdown)(this.countryDropdownView, countryCollection);
        this.sectionSubsectionDropdownView = (0, ckeditor5_ui_1.createDropdown)(locale);
        this.sectionSubsectionDropdownView.buttonView.set({
            label: models_2.DEFAULT_SECTION_LABEL,
            withText: true
        });
        this.subsectionDropdownView = (0, ckeditor5_ui_1.createDropdown)(locale);
        this.subsectionDropdownView.buttonView.set({
            label: models_2.DEFAULT_SUBSECTION_LABEL,
            withText: true
        });
        this.rulesDropdownView = (0, ckeditor5_ui_1.createDropdown)(locale);
        this.rulesDropdownView.buttonView.set({
            label: models_2.DEFAULT_SECTION_LABEL,
            withText: true
        });
        const saveButtonView = (0, utils_1.createButtonWithIcon)('Save', ckeditor5_core_1.icons.check, 'ck-button-save');
        saveButtonView.type = 'submit';
        this.unlinkButtonView = (0, utils_1.createButtonWithIcon)('Unlink', (0, utils_1.wrapInSvgElement)(models_1.unlinkIcon), 'ck-button-unlink');
        this.unlinkButtonView.delegate('execute').to(this, 'unlink');
        this.unlinkButtonView.isVisible = false;
        const cancelButtonView = (0, utils_1.createButtonWithIcon)('Cancel', ckeditor5_core_1.icons.cancel, 'ck-button-cancel');
        cancelButtonView.delegate('execute').to(this, 'cancel');
        this.labelView = new ckeditor5_ui_1.LabelView(locale);
        this.labelView.text = 'Insert Internal Hyperlink';
        const childViews = this.createCollection([
            new div_view_1.DivView(locale, ['ck', 'ck-hyperlink-title'], [this.labelView]),
            this.countryDropdownView,
            this.sectionSubsectionDropdownView,
            this.subsectionDropdownView,
            this.rulesDropdownView,
            new div_view_1.DivView(locale, ['ck', 'ck-hyperlink-buttons'], [saveButtonView, this.unlinkButtonView, cancelButtonView])
        ]);
        this.setTemplate({
            tag: 'form',
            attributes: {
                class: ['ck', 'ck-hyperlink-form'],
                tabindex: '-1'
            },
            children: childViews
        });
    }
    render() {
        super.render();
        (0, ckeditor5_ui_1.submitHandler)({ view: this });
    }
    createCountryDropdownDefinition(country) {
        return {
            type: 'button',
            model: new ckeditor5_ui_1.Model({
                withText: true,
                label: country.countryName,
                country
            })
        };
    }
}
exports.InternalHyperlinkFormView = InternalHyperlinkFormView;
