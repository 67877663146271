"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const ckeditor5_core_1 = require("@ckeditor/ckeditor5-core");
const models_1 = require("./models");
const utils_1 = require("../shared/utils");
class AddFootnoteCommand extends ckeditor5_core_1.Command {
    execute(footnote) {
        const editor = this.editor;
        const selection = editor.model.document.selection;
        editor.model.change(writer => {
            const footnoteElement = writer.createElement(models_1.FOOTNOTE_MODEL_ID, Object.assign(Object.assign(Object.assign({}, Object.fromEntries(selection.getAttributes())), (footnote.link ? { linkHref: footnote.link } : {})), { [models_1.FOOTNOTE_ID_KEY]: footnote.footnoteId }));
            const firstRange = selection.getFirstRange();
            const selectedText = firstRange
                ? [...firstRange.getItems()].filter((x) => x.is('text')).map(x => x.data)
                : [];
            const data = selectedText.length > 0 ? selectedText[0] : null;
            let lastPosition = selection.getLastPosition();
            if (data && lastPosition && (0, utils_1.lastCharIsWhitespace)(data)) {
                lastPosition = lastPosition.getShiftedBy(-1);
            }
            editor.model.insertContent(footnoteElement, lastPosition);
            writer.setSelection(footnoteElement, 'on');
        });
    }
    refresh() {
        var _a;
        const model = this.editor.model;
        const selection = model.document.selection;
        if (!((_a = selection.focus) === null || _a === void 0 ? void 0 : _a.parent) || !selection.focus.parent.is('node')) {
            return;
        }
        const isAllowed = model.schema.checkChild(selection.focus.parent, models_1.FOOTNOTE_MODEL_ID);
        this.isEnabled = isAllowed;
    }
}
exports.default = AddFootnoteCommand;
