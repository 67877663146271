"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getFootnotes = exports.formatFootnote = void 0;
const models_1 = require("./models");
function formatFootnote(footnote) {
    return `${footnote.footNoteNameCod} ${footnote.label}`;
}
exports.formatFootnote = formatFootnote;
function getFootnotes(editor) {
    return editor.config.get(models_1.CONFIG_KEY).getFootnotes();
}
exports.getFootnotes = getFootnotes;
