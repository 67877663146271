"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DivView = void 0;
const ckeditor5_ui_1 = require("@ckeditor/ckeditor5-ui");
class DivView extends ckeditor5_ui_1.View {
    constructor(locale, classes, children) {
        super(locale);
        this.setTemplate({
            tag: 'div',
            attributes: {
                class: classes
            },
            children
        });
    }
}
exports.DivView = DivView;
