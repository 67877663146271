"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AddHyperlinkCommand = void 0;
const ckeditor5_core_1 = require("@ckeditor/ckeditor5-core");
const ckeditor5_typing_1 = require("@ckeditor/ckeditor5-typing");
const ckeditor5_utils_1 = require("@ckeditor/ckeditor5-utils");
const utils_1 = require("./utils");
const models_1 = require("./models");
class AddHyperlinkCommand extends ckeditor5_core_1.Command {
    constructor() {
        super(...arguments);
        this.section = null;
        this.subsectionDescription = null;
    }
    execute(params) {
        var _a;
        if (params.internal) {
            this.section = params.section;
            this.subsectionDescription = (_a = params.subsectionDescription) !== null && _a !== void 0 ? _a : null;
        }
        else {
        }
        const model = this.editor.model;
        const selection = model.document.selection;
        model.change(writer => {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
            // If selection is collapsed then update selected link or insert new one at the place of caret.
            if (selection.isCollapsed) {
                const position = selection.getFirstPosition();
                // When selection is inside text with `linkHref` attribute.
                if (selection.hasAttribute(models_1.HYPERLINK_ATTRIBUTE_KEY)) {
                    // Then update `linkHref` value.
                    const range = (0, ckeditor5_typing_1.findAttributeRange)(position, models_1.HYPERLINK_ATTRIBUTE_KEY, selection.getAttribute(models_1.HYPERLINK_ATTRIBUTE_KEY).toString(), model);
                    const hyperlinkPayload = {
                        countryId: (_b = (_a = this.section) === null || _a === void 0 ? void 0 : _a.countryId) !== null && _b !== void 0 ? _b : 'ERROR AddInternalHyperlinkCommand',
                        href: params.url,
                        sectionNameId: (_d = (_c = this.section) === null || _c === void 0 ? void 0 : _c.sectionNameId.toString()) !== null && _d !== void 0 ? _d : 'ERROR AddInternalHyperlinkCommand',
                        subsectionId: params.internal ? (_e = params.subsectionId) !== null && _e !== void 0 ? _e : undefined : undefined,
                        internal: params.internal
                    };
                    writer.setAttribute(models_1.HYPERLINK_ATTRIBUTE_KEY, JSON.stringify(hyperlinkPayload), range);
                    // Put the selection at the end of the updated link.
                    writer.setSelection(writer.createPositionAfter(range.end.nodeBefore));
                }
                // Remove the `linkHref` attribute.
                // It stops adding a new content into the link element.
                writer.removeSelectionAttribute(models_1.HYPERLINK_ATTRIBUTE_KEY);
            }
            else {
                // If selection has non-collapsed ranges, we change attribute on nodes inside those ranges
                // omitting nodes where the `linkHref` attribute is disallowed.
                const ranges = model.schema.getValidRanges([...selection.getRanges()], models_1.HYPERLINK_ATTRIBUTE_KEY);
                // But for the first, check whether the `linkHref` attribute is allowed on selected blocks (e.g. the "image" element).
                const allowedRanges = [];
                for (const element of selection.getSelectedBlocks()) {
                    if (model.schema.checkAttribute(element, models_1.HYPERLINK_ATTRIBUTE_KEY)) {
                        allowedRanges.push(writer.createRangeOn(element));
                    }
                }
                // Ranges that accept the `linkHref` attribute. Since we will iterate over `allowedRanges`, let's clone it.
                const rangesToUpdate = allowedRanges.slice();
                // For all selection ranges we want to check whether given range is inside an element that accepts the `linkHref` attribute.
                // If so, we don't want to propagate applying the attribute to its children.
                for (const range of ranges) {
                    if (this._isRangeToUpdate(range, allowedRanges)) {
                        rangesToUpdate.push(range);
                    }
                }
                for (const range of rangesToUpdate) {
                    writer.removeAttribute('htmlA', range);
                    const hyperlinkPayload = {
                        countryId: (_g = (_f = this.section) === null || _f === void 0 ? void 0 : _f.countryId) !== null && _g !== void 0 ? _g : 'ERROR AddInternalHyperlinkCommand',
                        href: params.url,
                        sectionNameId: (_j = (_h = this.section) === null || _h === void 0 ? void 0 : _h.sectionNameId.toString()) !== null && _j !== void 0 ? _j : 'ERROR AddInternalHyperlinkCommand',
                        subsectionId: params.internal ? (_k = params.subsectionId) !== null && _k !== void 0 ? _k : undefined : undefined,
                        internal: params.internal
                    };
                    writer.setAttribute(models_1.HYPERLINK_ATTRIBUTE_KEY, JSON.stringify(hyperlinkPayload), range);
                }
            }
        });
    }
    refresh() {
        this.isEnabled = true;
        const model = this.editor.model;
        const selection = model.document.selection;
        const selectedElement = selection.getSelectedElement() || (0, ckeditor5_utils_1.first)(selection.getSelectedBlocks());
        // A check for any integration that allows linking elements (e.g. `LinkImage`).
        // Currently the selection reads attributes from text nodes only. See #7429 and #7465.
        if ((0, utils_1.isLinkableElement)(selectedElement, model.schema)) {
            this.value = selectedElement.getAttribute(models_1.HYPERLINK_ATTRIBUTE_KEY);
            this.isEnabled = model.schema.checkAttribute(selectedElement, models_1.HYPERLINK_ATTRIBUTE_KEY);
        }
        else {
            this.value = selection.getAttribute(models_1.HYPERLINK_ATTRIBUTE_KEY);
            this.isEnabled = model.schema.checkAttributeInSelection(selection, models_1.HYPERLINK_ATTRIBUTE_KEY);
        }
    }
    _isRangeToUpdate(range, allowedRanges) {
        for (const allowedRange of allowedRanges) {
            // A range is inside an element that will have the `internalHyperlink` attribute. Do not modify its nodes.
            if (allowedRange.containsRange(range)) {
                return false;
            }
        }
        return true;
    }
}
exports.AddHyperlinkCommand = AddHyperlinkCommand;
