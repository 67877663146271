"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RemoveHyperlinkCommand = void 0;
const ckeditor5_core_1 = require("@ckeditor/ckeditor5-core");
const ckeditor5_typing_1 = require("@ckeditor/ckeditor5-typing");
const models_1 = require("./models");
const utils_1 = require("./utils");
class RemoveHyperlinkCommand extends ckeditor5_core_1.Command {
    constructor() {
        super(...arguments);
        this.url = '';
        this.section = null;
        this.subsectionDescription = null;
    }
    execute(params) {
        var _a;
        if (params.internal) {
            this.url = params.url;
            this.section = params.section;
            this.subsectionDescription = (_a = params.subsectionDescription) !== null && _a !== void 0 ? _a : null;
        }
        const model = this.editor.model;
        const selection = model.document.selection;
        model.change(writer => {
            // Get ranges to unlink.
            const rangesToUnlink = selection.isCollapsed
                ? [(0, ckeditor5_typing_1.findAttributeRange)(selection.getFirstPosition(), models_1.HYPERLINK_ATTRIBUTE_KEY, selection.getAttribute(models_1.HYPERLINK_ATTRIBUTE_KEY).toString(), model)]
                : model.schema.getValidRanges([...selection.getRanges()], models_1.HYPERLINK_ATTRIBUTE_KEY);
            // Remove `internalHyperlink` attribute from specified ranges.
            for (const range of rangesToUnlink) {
                writer.removeAttribute('htmlA', range);
                writer.removeAttribute(models_1.HYPERLINK_ATTRIBUTE_KEY, range);
            }
        });
    }
    refresh() {
        const model = this.editor.model;
        const selection = model.document.selection;
        const selectedElement = selection.getSelectedElement();
        // A check for any integration that allows linking elements (e.g. `LinkImage`).
        // Currently the selection reads attributes from text nodes only. See #7429 and #7465.
        if ((0, utils_1.isLinkableElement)(selectedElement, model.schema)) {
            this.isEnabled = model.schema.checkAttribute(selectedElement, models_1.HYPERLINK_ATTRIBUTE_KEY);
        }
        else {
            this.isEnabled = model.schema.checkAttributeInSelection(selection, models_1.HYPERLINK_ATTRIBUTE_KEY);
        }
    }
}
exports.RemoveHyperlinkCommand = RemoveHyperlinkCommand;
