"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FootnotesFormView = void 0;
const ckeditor5_utils_1 = require("@ckeditor/ckeditor5-utils");
const ckeditor5_ui_1 = require("@ckeditor/ckeditor5-ui");
const utils_1 = require("@ckeditor/ckeditor5-ui/src/dropdown/utils");
const ckeditor5_core_1 = require("@ckeditor/ckeditor5-core");
const models_1 = require("./models");
const utils_2 = require("./utils");
const div_view_1 = require("../shared/div-view");
const utils_3 = require("../shared/utils");
const models_2 = require("../shared/models");
class FootnotesFormView extends ckeditor5_ui_1.View {
    constructor(locale, getDropdownItems) {
        super(locale);
        this.getDropdownItems = getDropdownItems;
        this.unlinkButtonView = null;
        this.dropdownItems = [];
        this.dropdownItems = getDropdownItems();
        this.dropdownView = (0, utils_1.createDropdown)(locale);
        this.dropdownView.buttonView.set({
            label: models_1.DEFAULT_DROPDOWN_LABEL,
            withText: true
        });
        this.updateDropdown();
        const saveButtonView = (0, utils_3.createButtonWithIcon)('Save', ckeditor5_core_1.icons.check, 'ck-button-save');
        saveButtonView.type = 'submit';
        const cancelButtonView = (0, utils_3.createButtonWithIcon)('Cancel', ckeditor5_core_1.icons.cancel, 'ck-button-cancel');
        cancelButtonView.delegate('execute').to(this, 'cancel');
        this.unlinkButtonView = (0, utils_3.createButtonWithIcon)('Unlink', (0, utils_3.wrapInSvgElement)(models_2.unlinkIcon), 'ck-button-unlink');
        this.unlinkButtonView.isVisible = false;
        this.unlinkButtonView.delegate('execute').to(this, 'unlink');
        const childViews = this.createCollection([
            new div_view_1.DivView(locale, ['ck', 'ck-footnote-title'], ['Insert footnote']),
            this.dropdownView,
            new div_view_1.DivView(locale, ['ck', 'ck-footnote-buttons'], [saveButtonView, this.unlinkButtonView, cancelButtonView])
        ]);
        this.setTemplate({
            tag: 'form',
            attributes: {
                class: ['ck', 'ck-footnote-form'],
                tabindex: '-1'
            },
            children: childViews
        });
    }
    render() {
        super.render();
        (0, ckeditor5_ui_1.submitHandler)({ view: this });
    }
    updateDropdown() {
        if (this.dropdownView.listView) {
            this.dropdownView.listView.items.clear();
        }
        this.dropdownItems = this.getDropdownItems();
        const items = new ckeditor5_utils_1.Collection(this.dropdownItems.map(footnote => this.createDropdownDefinition(footnote)));
        (0, utils_1.addListToDropdown)(this.dropdownView, items);
    }
    createDropdownDefinition(footnote) {
        return {
            type: 'button',
            model: new ckeditor5_ui_1.Model({
                withText: true,
                label: (0, utils_2.formatFootnote)(footnote),
                footnote
            })
        };
    }
}
exports.FootnotesFormView = FootnotesFormView;
